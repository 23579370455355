import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import billpay from "../images/billgo/billpay.webp";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="BillGO" keywords={['Seraph', 'kumar', 'designer', 'user', 'experience', 'product', 'interaction', 'b2b', 'saas', 'web', 'app', 'platform', 'gatsby', 'application', 'react']} mdxType="SEO" />
    <img height="auto" loading="eager" src={billpay} alt="Mockup of bill pay.." className="center" /> <br /> <br />
    <h1>{`Designing for Payments`}</h1>
    <div className="nda"> Content is limited and altered to abide by NDAs </div>
    <h2>{`BillGO`}</h2>
    <p>{`BillGO's flaghsip product is a white label bill pay platform. Financial institutions like Capital One
and USAA Bank rely on BillGo's bill pay in their apps and websites.`}</p>
    <h2>{`Alerts for Bill Pay`}</h2>
    <p>{`I discovered that bill pay doesn't notify users of errors. This caused users to miss payments.
I designed an alerts notification system to immediately notify users. `}</p>
    <p>{`The home page populates with cards that clearly communicate what the error is and how the user can fix it.
I placed a contextual call-to-action button that starts the error fixing flow. The cards also use symbols, colors and
heirarchy to communicate to the user which errors should be fixed first.`}</p>
    <p>{`Once the feature launched, virtually all users began fixing errors before it was too late.`}</p>
    <Link to="/" mdxType="Link">Home</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      